import * as React from 'react'
import { graphql } from 'gatsby';
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import IndexLayout from '../components/layouts'
// const Bold = ({ children }: any) => <span className="bold">{children}</span>
// const Text = ({ children }: any) => <p className="align-center">{children}</p>
const options: any = {
  renderMark: {
    // [MARKS.BOLD]: (text: any) => <Bold>{text}</Bold>,
  },
  renderNode: {
    // [BLOCKS.PARAGRAPH]: (node: any, children: any) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      return (
        <img src={node?.data.target.fixed.src} alt={''}  />
        // <>
        //   <h2>Embedded Asset</h2>
        //   <pre>
        //     <code>{JSON.stringify(node, null, 2)}</code>
        //   </pre>
        // </>
      )
    },
  },
}

const IndexPage = (props: any) => {
  const { data } = props;
  const { title, publishDate, category, body, tags, metaTags, metaDescription, navBarColor, navBarStickyColor, content } = data.page;

  // const { childMarkdownRemark: { html } } = content;
  // const { raw } = data.page.content;

  console.log('data.page', data.page);
  const RichText = data.page.content ? renderRichText(data.page.content, options) : null;
  // const BlockElemnts = blocks.map(b => blockResolve(b))

  return (
  <IndexLayout>
    <header className="header text-white bg-dark pt-9 pb-5" style={{backgroundImage: "linear-gradient(-20deg, #2b5876 0%, #4e4376 100%)"}}>
      <div className="container">
        <h3>Knowledge Base 333</h3>
        {/* <div dangerouslySetInnerHTML={{ __html: html }}>
        </div> */}
        <hr/>
        {RichText}
        {/* <div dangerouslySetInnerHTML={{ __html: html }}> */}

      

      </div>
    </header>

  </IndexLayout>
  );
}


export default IndexPage


export const pageQuery = graphql`
  query ($slug: String){
    page: contentfulDocsArticle (slug: {eq: $slug}) {
      id
      title
      content {
        raw 
      }
    }
  }
`